import Link from "next/link";
import { Head } from "@/components/base/Head";
import React from "react";
import { SectionTitle } from "@/components/base/ui/SectionTitle";

const NotFoundPage = () => (
  <>
    <Head title={"404 Not Found"} canonicalPath={"/404"} />
    <main className={"bg-gray-100 py-20 px-2"}>
      <div className={"max-w-[960px] bg-white m-auto p-10"}>
        <div className={"mb-8"}>
          <SectionTitle title={"404"} isBorder={false} />
        </div>
        <p className={"mb-8"}>
          お探しのページは削除されたか、別のURLに移動しました。
        </p>
        <Link href="/" className={"underline text-blue-700"}>
          トップページへ戻る
        </Link>
      </div>
    </main>
  </>
);

export default NotFoundPage;
